@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Black.woff2') format('woff2'),
      url('assets/font/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-ExtraBold.woff2') format('woff2'),
      url('assets/font/Montserrat-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.woff2') format('woff2'),
      url('assets/font/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Light.woff2') format('woff2'),
      url('assets/font/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.woff2') format('woff2'),
      url('assets/font/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Thin.woff2') format('woff2'),
      url('assets/font/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-SemiBold.woff2') format('woff2'),
      url('assets/font/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Medium.woff2') format('woff2'),
      url('assets/font/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-ExtraLight.woff2') format('woff2'),
      url('assets/font/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

