/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Fonts */
@import "./font.scss";

//noinspection CssUnknownTarget
@import "swiper/scss";
//noinspection CssUnknownTarget
@import "swiper/scss/navigation";
//noinspection CssUnknownTarget
@import "swiper/scss/pagination";

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

input,
textarea {
  caret-color: var(--ion-color-light-contrast);
}

.ios {
  ion-modal {
    --min-width: 100%;
    --min-height: 100%;

    @media (min-height: 768px) {
      ion-header ion-toolbar {
        --border-radius: unset !important;

        &:first-of-type {
          padding-top: 50px;
        }
      }
    }
  }
}

ion-modal.authModal {
  --background: transparent;
  --box-shadow: 0;
  --backdrop-opacity: 0.8 !important;
}

ion-thumbnail {
  margin-block: auto;
}

.item .text-small {
  font-size: 0.8rem;
}

ion-toolbar {
  --background: var(--primary-color);
  --color: #fff;
}

ion-title {
  font-size: 1rem;
}

a {
  cursor: pointer;
}

.hr {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.margin-inline-auto {
  margin-inline: auto;
}

ion-text, p {
  line-height: calc(1rem * 1.5);
}

.no-shadow {
  --box-shadow: none;
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}

.scale-effect {
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.swiper-pagination-bullets {
  bottom: 20px !important;
  padding: 20px 0;

  .swiper-pagination-bullet-active {
    background-color: var(--ion-color-secondary-shade) !important;
  }
}

ion-input {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--ion-item-background) inset;
    -webkit-text-fill-color: var(--ion-color-light-contrast);

    &.native-input {
      padding-top: 0;
      padding-bottom: 0;

      margin-top: var(--padding-top);
      margin-bottom: var(--padding-bottom);
    }
  }
}

ion-menu-button {
  --color: var(--ion-color-secondary);
}

.capcana-response {
  user-select: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 2px solid #ffffff;
  line-height: 1.6;

  &.correct {
    color: var(--ion-color-success);
  }

  &.incorrect {
    color: var(--ion-color-danger);
  }
}

p.dot {
  &::before {
    content: "•";
    font-size: 2rem;
    vertical-align: sub;
    margin-right: 5px;
  }
}

ion-header {
  ion-button,
  ion-back-button {
    --color: var(--ion-color-secondary);
  }
}

.strike {
  text-decoration: line-through;
}

.hr {
  width: 100%;
  height: var(--ion-padding, 20px);
  border-bottom: 0.5px solid #ccc;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.or {
  font-size: 1rem;
  font-weight: 300;
  color: #ccc;
  background-color: var(--ion-item-background);
  position: absolute;
  top: 100%;
  transform: translate(-50%, -50%);
  padding-inline: 20px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

ion-radio {
  width: 20px;
  height: 20px;

  &::part(container) {
    border-radius: 8px;
    border: 0;
    background: #ffffff;
  }

  &::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  &.radio-checked {
    &::part(container) {
      background: var(--ion-color-principal);
    }

    &::part(mark) {
      width: 6px;
      height: 13px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #ffffff;
      transform: rotate(45deg);
    }
  }
}

ion-checkbox {
  width: 20px;
  height: 20px;

  --checkmark-color: #ffffff;
  --background: #ffffff;
  --background-checked: var(--ion-color-principal);

  &::part(container) {
    border-radius: 8px;
    border: 0;
    padding: 3px;
  }
}

.alert-checkbox-label {
  //wrap the label text
  white-space: normal !important;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

@media (max-width: 576px) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 500;
}

.or {
  text-transform: uppercase;
}

ion-item {
  white-space: pre-line !important;
}

.text-white {
  color: #ffffff;
}

.text-success {
  color: var(--ion-color-success);
}

.text-warning {
  color: var(--ion-color-warning);
}

.text-danger {
  color: var(--ion-color-danger);
}

.alert-tappable {
  height: 60px !important;
}

.a-white {
  a {
    color: #ffffff !important;
  }
}

.alertEmailConfirmation {
  #alert-1-msg {
    padding-bottom: 5px ;
  }

  .alert-input-group {
    padding: 0 20px !important;
  }
}

